
import IERC20 from "./IERC20.min.json";
import Web3 from "web3";

export default class IERC20Game {

    tokenaddress = null
    token = null
    gameaddress = null
    playeraddress = null
    ercHandler = null
    maxUnlock = '115792089237316195423570985008687907853269984665640564039457584007913129639935'
    minUnlock = '38597363079105398474523661669562635951089994888546854679819194669304376546645'
    tokenObj = null


    constructor(_tokenObject, _gameaddress, _playeraddress, _ercHandler) {
        this.ercHandler = _ercHandler
        this.playeraddress = _playeraddress
        this.gameaddress = _gameaddress
        this.tokenObj = _tokenObject
        this.tokenaddress = _tokenObject.address
    }

   async unlock() {
        var sendData = {
            from: this.playeraddress
        }
        if (this.tokenObj.extraFeeGasLimit > 0) {
            var gasLimit = await this.token.methods.approve(this.gameaddress, this.maxUnlock).estimateGas(
                { from: this.playeraddress }
            )
            gasLimit = parseInt(gasLimit * this.tokenObj.extraFeeGasLimit)

            var gasPrice = await window.web3.eth.getGasPrice()
            gasPrice = parseInt(gasPrice * this.tokenObj.extraFeeGasPrice)


            sendData.gasLimit = gasLimit
            sendData.gasPrice = gasPrice
        }

        this.token.methods
            .approve(this.gameaddress, this.maxUnlock)
            .send(sendData)
            .then(function (result) {
                if (this.ercHandler) {
                    var res = false
                    try {
                        res = result.events.Approval.returnValues.value.length > 0
                    } catch (error) {
                        window.Logger.log(error)
                    }
                    this.ercHandler({ type: "unlock", value: res })
                }
            }.bind(this), function (params) {
                window.Logger.log(params)
            }.bind(this))
    }

    init() {
        this.token = new window.web3.eth.Contract(IERC20.abi, this.tokenaddress);
        window.Logger.log(this.playeraddress + " " + this.gameaddress)
        this.token.methods
            .allowance(this.playeraddress, this.gameaddress)
            .call({ from: this.playeraddress })
            .then(function (result) {
                window.Logger.log(result)
                if (this.ercHandler) {
                    var c = Web3.utils.toBN(result)
                    var m = Web3.utils.toBN(this.minUnlock)
                    this.ercHandler({ type: "allowance", value: !c.lt(m) })
                }
            }.bind(this), function (params) {
                window.Logger.log(params)
            }.bind(this))

        this.balanceOf()
    }

    balanceOf() {
        this.token.methods
            .balanceOf(this.playeraddress)
            .call({ from: this.playeraddress })
            .then(function (result) {
                if (this.ercHandler) {
                    this.ercHandler({ type: "balance", value: result })
                }
            }.bind(this), function (params) {
                window.Logger.log(params)
            }.bind(this))
    }
}

