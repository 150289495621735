
//import Cashback from "../games/dice/contracts/Cashback.min.json";
import Web3 from "web3";


export default class TokenHelper {
    balance = 0
    contract = null
    address = null
    account = null
    Token = null
    lastBlock = 0
    getLogsDataInterval = 0
    processedTxs = []

    constructor(_messageHandler, _token) {
        this.messageHandler = _messageHandler
        this.Token = _token
    }

    getNetworkVersion() {
        var networkV = 0
        try {
            if (window.web3.currentProvider.isViaWallet) {
                networkV = window.web3.currentProvider.chainId
            } else {
                networkV = window.web3.eth.currentProvider.networkVersion
            }
        } catch (error) {
            window.Logger.log(error)
        }

        return networkV
    }
    claim() {
        this.contract.methods
            .claim()
            .send({ from: this.account })
            .then(function (result) {
                window.Logger.log(result)
            }.bind(this), function (params) {
                this.messageHandler({ type: 'error', msg: 'Token rejected code:' + params })
            }.bind(this))
    }

    balanceOf() {
        console.log("balanceOf call")
        this.contract.methods
            .balanceOf(this.account)
            .call({ from: this.account })
            .then(function (result) {
                console.log("tokenbalance",result)
                this.messageHandler({
                    type: 'tokenbalance',
                    address: this.account,
                    balance: Web3.utils.fromWei(result, 'ether'),
                    amount: Web3.utils.fromWei(result, 'ether')
                })
            }.bind(this), function (params) {
                this.messageHandler({ type: 'error', msg: 'Token rejected code:' + params })
            }.bind(this))
    }

    onConnet(_account) {
        window.Logger.log("bbbbbbbbbbb onConnet "+this.getNetworkVersion())
        if (this.Token.networks[this.getNetworkVersion()] == null) {
            return;
        }
        window.Logger.log("bbbbbbbbbbb onConnet1")
        this.account = _account
        window.Logger.log("bbbbbbbbbbb this.account " + this.account)
        this.address = this.Token.networks[this.getNetworkVersion()].address;
        this.contract = new window.web3.eth.Contract(
            this.Token.abi,
            this.address
        );
        console.log(this.contract)
        console.log(this.Token)
        if (window.tokenaddress) {
            if (window.tokenaddress[this.address] == true) {
                return
            }
        }
        if (window.tokenaddress == null) {
            window.tokenaddress = {}
        }
        window.tokenaddress[this.address] = true

        /*window.web3.eth.getBlockNumber().then(function (data) {
            this.lastBlock = data;
            this.getLogsDataInterval = setInterval(this.getLogsData.bind(this), 1000);
        }.bind(this));*/
    }
    destroy() {
        clearInterval(this.getLogsDataInterval)
        if (window.tokenaddress) {
            window.tokenaddress[this.address] = false
        }
    }
    /*
    getLogsData() {
        if (this.lastBlock > 0) {
            this.contract.events.allEvents({
                fromBlock: this.lastBlock
            }).on('data', function (event) {
                if (this.processedTxs.indexOf(event.transactionHash) == -1) {
                    this.processedTxs.push(event.transactionHash)
                    this.lastBlock = event.blockNumber + 1
                    if (
                        this.account.toUpperCase() == event.returnValues.player.toUpperCase()
                    ) {
                        switch (event.event) {
                            case 'Add':
                                this.messageHandler({
                                    type: 'cashbackadd',
                                    address: event.returnValues.player,
                                    balance: Web3.utils.fromWei(event.returnValues.balance, 'ether'),
                                    amount: Web3.utils.fromWei(event.returnValues.amount, 'ether')
                                })
                                break;
                            case 'Claim':
                                this.messageHandler({
                                    type: 'cashbackclaim',
                                    address: event.returnValues.player,
                                    amount: Web3.utils.fromWei(event.returnValues.balance, 'ether')
                                })
                                break
                            default:
                                break;
                        }
                    }
                }

                if (this.processedTxs.length > 30) {
                    this.processedTxs.pop()
                }

            }.bind(this))
        }
    }*/



}

