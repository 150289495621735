
import Web3 from "web3";

export default class HistoryWatcher {
    logsHandler = null
    contractData = null
    network = null
    ws = null
    web3History = null
    web3WebSocket = null
    processedTxs = []
    chain = null
    
    constructor(_logsHandler, _contractData, _chain) {
        this.logsHandler = _logsHandler
        this.contractData = _contractData
        this.chain = _chain
        console.log("HistoryWatcher " + this.chain.network + " " + this.chain.wss)

    }
    destroy() {
        if (this.web3History) {
            this.web3History.currentProvider.disconnect()
        }

    }

    init() {
        if (this.contractData.networks[this.chain.network] && this.contractData.networks[this.chain.network].address) {
            const options = {
                timeout: 30000, // ms

                clientConfig: {
                    // Useful if requests are large
                    maxReceivedFrameSize: 65536,   // bytes - default: 1MiB
                    maxReceivedMessageSize: 65536, // bytes - default: 8MiB

                    // Useful to keep a connection alive
                    keepalive: true,
                    keepaliveInterval: -1 // ms
                },
                origin:"*",
                // Enable auto reconnection
                reconnect: {
                    auto: true,
                    delay: 1000, // ms
                    maxAttempts: 10,
                    onTimeout: false
                }
            };

            this.web3WebSocket = new Web3.providers.WebsocketProvider(this.chain.wss, options)

            this.web3WebSocket.on('connect', function () {
                console.log("WS connected");
            });
            this.web3WebSocket.on('error', function () {
                console.log("WS not connected");
            });
            this.web3WebSocket.on('end', e => {
                console.log(e);
                console.log('WS closed');
            });

            this.web3History = new Web3(this.web3WebSocket);
            this.address = this.contractData.networks[this.chain.network].address;
            this.contract = new this.web3History.eth.Contract(
                this.contractData.abi,
                this.address
            );
            this.fetchHistory()
        }


    }

    fetchHistory() {
        if (this.web3History) {
            this.web3History.eth.getBlockNumber().then(function (data) {
                this.lastBlock = data;
                this.getLogsData()
            }.bind(this));
        }

    }

    getLogsData() {
        if (this.lastBlock > 0) {
            this.contract.events.allEvents({
                fromBlock: this.lastBlock
            }).on('data', function (event) {
                if (this.processedTxs.indexOf(event.transactionHash) == -1) {
                    this.processedTxs.push(event.transactionHash)
                    this.lastBlock = event.blockNumber + 1
                    if (this.logsHandler) {
                        var tokenname = event.returnValues.token;
                        if(tokenname == null){
                            tokenname = event.returnValues.token_address;
                        }
                        if(tokenname == null){
                            tokenname = "0x0000000000000000000000000000000000000000";
                        }
                        var token = this.getTokensByAdress(tokenname)
                        
                        this.logsHandler(event, token)
                    }
                }
                if (this.processedTxs.length > 30) {
                    this.processedTxs.pop()
                }

            }.bind(this))
        }
    }

    getTokensByAdress(address) {
        var tokens = this.chain.tokens
        for (const key in tokens) {
            if (Object.hasOwnProperty.call(tokens, key)) {
                const element = tokens[key];
                if (element.address == address) {
                    return element;
                }
            }
        }

        if (this.chain.default_token.address == address) {
            return this.chain.default_token
        }

        return null
    }

}

