import Web3 from "web3";
import { store } from '@/store';
import IERC20Game from "./erc20token"


export default class Game {
    contractData = null
    contract = null
    getLogsDataInterval = 0
    balance = 0
    connected = false
    account = null

    address = null
    messageHandler = null
    logsHandler = null
    game = ''
    currency = store.getters.getConfig.currency_code

    erc20Game = null
    tokenaddress = null
    gameaddress = null
    playeraddress = null
    networkVersion = 0
    tokenObj = { address: '' }
    processedTxs = []
    rndid = parseInt(Math.random() * 100000)


    constructor(_messageHandler, _contractData, _game) {
        this.messageHandler = _messageHandler
        this.contractData = _contractData
        this.game = _game
        this.networkVersion = store.getters.getConfig.network

    }

    getNetworkVersion() {
        var networkV = 0
        try {
            if (window.ethereum && window.ethereum.networkVersion) {
                networkV = window.ethereum.networkVersion
            } else if (window.web3.currentProvider.isViaWallet ||window.web3.currentProvider.isSafePal) {
                networkV = window.web3.currentProvider.chainId
            } else if (window.web3.currentProvider.networkVersion!="") { // NEW
                networkV = window.web3.currentProvider.networkVersion; // NEW
            } else {
                networkV = window.web3.eth.currentProvider.networkVersion
            }
        } catch (error) {
            window.Logger.log(error)
        }

        return networkV
    }


    destroy() {
        clearInterval(this.getLogsDataInterval)
        clearInterval(this.accountIntervalCheck)
    }

    connect() {
        window.Logger.log("connect")
        if (this.connected) {
            return;
        }
        this.universalSetupPlan()
    }

    init() {
        window.Logger.log('init');
        this.universalSetupPlan()
    }


    universalSetupPlan() {
        if (window.ethereum) {
            if (window.ethereum.isTrust && !window.ethereum.isMetaMask) {
                window.Logger.log('Trust wallet case');
                if (window.web3) {
                    window.Logger.log('Web3 present')
                }

                window.web3 = new Web3(window.ethereum); // dzvel kodshi jer es isetebeoda

                window.ethereum
                .enable() // Gansxvaveba es aris
                .then(function(){
                    window.web3.eth.getAccounts(function (err, accounts) {
                        if (err != null) {
                            window.Logger.log(err);
                            window.Logger.log(err.stack);
                        } else if (!accounts) {
                            window.Logger.log('No accounts')
                        } else if (accounts.length == 0) {
                            window.Logger.log('Accounts length 0')
                            this.messageHandler({ type: 'notconnected' })
                        } else {
                            window.Logger.log("Connected")
                            this.fetchAccount() // es crurcmenis pontshi chavsvat ak ikneb imushavos
                            this.onConnet()
                        }
                    }.bind(this));

                    //this.onConnet()
                }.bind(this))
                .catch(function(err) {
                    this.messageHandler({ type: 'rejected' })
                    if (err.code === 4001) {
                        window.Logger.log('User rejected wallet connection');
                    } else {
                        window.Logger.log(err);
                    }
                });
            } else {

                window.Logger.log('Regular case');

                window.ethereum.request({ method: 'eth_requestAccounts' })
                .then(function(){
                    window.web3 = new Web3(window.ethereum);
                    this.onConnet()
                }.bind(this))
                .catch(function(err) {
                    this.messageHandler({ type: 'rejected' })
                    if (err.code === 4001) {
                        window.Logger.log('User rejected wallet connection');
                    } else {
                        window.Logger.log(err);
                    }
                }.bind(this));

            }


        } else {
            window.Logger.log("No ethereum")
            if (this.web3) {
                window.Logger.log("But web3 present")
            }
        }
    }



    onConnet() {

        // https://stackoverflow.com/questions/67597665/how-to-change-network-in-metamask-using-react-js
        //window.Logger.log("window.web3:")

        //for (const [key] of Object.entries(window.web3.eth)) {
        //    window.Logger.log("key:"+key)
        // }
        window.Logger.log("this.connected: "+this.connected+" "+this.getNetworkVersion())
        if (this.connected) {
            return;
        }

        var loadRes = this.loadContract(); // NEW ak chavtvirtot kontrakti
        if(!loadRes){
            this.messageHandler({
                type: 'networkchange',
                networkVersion: this.getNetworkVersion()
            })
        }


        if (this.contractData.networks[this.getNetworkVersion()] == null) {
            this.messageHandler({ type: 'error', msg: 'Wrong network selected. Please change network!'})
            return;
        }
        this.address = this.contractData.networks[this.getNetworkVersion()].address;

        var accountInterval = setInterval(function () {
            if (this.contractData.networks[this.getNetworkVersion()] == null) {
                clearInterval(accountInterval)
                window.web3.eth.clearSubscriptions();
                this.messageHandler({ type: 'notconnected' })
                this.waitForAccount()
            }
        }.bind(this), 100);

        this.fetchAccount();
        if (this.networkVersion != this.getNetworkVersion()) {
            this.messageHandler({
                type: 'networkchange',
                networkVersion: this.getNetworkVersion()
            })
        }


        this.networkVersion = this.getNetworkVersion()
        this.accountIntervalCheck = setInterval(function () {

            if (this.networkVersion != this.getNetworkVersion()) {
                clearInterval(this.accountIntervalCheck)
                this.messageHandler({
                    type: 'networkchange',
                    networkVersion: this.getNetworkVersion()
                })

            }
        }.bind(this), 1000);
        this.connected = true;
        this.messageHandler({ type: 'connected' })
        window.Logger.log("AAAAAAAAA CONNECTED")
    }




    waitForAccount() {
        var accountInterval = setInterval(function () {
            if (this.contractData.networks[this.getNetworkVersion()] != null) {
                clearInterval(accountInterval)
                this.onConnet();
            }
        }.bind(this), 100);
    }


    debug(message) {
        this.messageHandler({ type: 'error', msg: message})
    }



    loadContract() {

        // NEW shemovitane shignit
        if (this.contractData.networks[this.getNetworkVersion()] == null) {
            return false
        }
        this.address = this.contractData.networks[this.getNetworkVersion()].address;
        this.contract = new window.web3.eth.Contract(
            this.contractData.abi,
            this.address
        );
        window.Logger.log('BBBBBB contract should not be null')
        window.Logger.log(this.contract)
        // NEW SHemovitane shignit
        return true
    }

    fetchAccount() {
        try {
            window.web3.eth.getAccounts().then(
                function (accounts) {
                    //window.Logger.log(typeof window.web3.requestAccounts)
                    //window.Logger.log("fetchAccount222 ("+this.account+") "+accounts)
                    window.Logger.log("fetchAccount "+accounts[0]+" "+this.account)
                    if (this.account == null) {
                        this.account = accounts[0];
                        this.messageHandler({ type: 'accountset', msg: accounts[0] })
                    }
                    //
                    this.account = accounts[0];
                    
                    window.web3.eth.getBalance(accounts[0]).then(
                        function (balance) {
                            window.Logger.log("fetchAccount balance "+balance)
                            this.balance = Web3.utils.fromWei(balance, "ether");
                            this.messageHandler({ type: 'balance', balance: this.balance, currency: this.currency })
                        }.bind(this)
                    );
                }.bind(this)
            );
        } catch (error) {
            window.Logger.log(error);
        }
    }

    setCurrency(_tokenObj) {
        console.log(_tokenObj)
        if (!this.connected){
            this.messageHandler({ type: 'error', msg: 'Not connected'})
            return;
        }

        this.tokenObj = _tokenObj
        if (this.tokenObj.address == '0x0000000000000000000000000000000000000000') {

            this.fetchAccount(); // ise amis gamodzaxeba bevrjer rad gvinda?
                                 // tan asinkronulia, kveda kods ar chirdeba

            // NEW - es connected shi shemovitane
            this.contract.methods
            .get_config()
            .call({ from: this.account })
            .then(function (result) {
                if (result["1"]) {
                    this.messageHandler({
                        type: 'limits',
                        min: Web3.utils.fromWei(result["0"], this.tokenObj.decimals),
                        max: Web3.utils.fromWei(result["1"], this.tokenObj.decimals)
                    })
                }
            }.bind(this), function (params) {
                this.messageHandler({ type: 'error', msg: 'User rejected code:' + params.code })
                this.messageHandler({ type: 'betbutton' })
            }.bind(this))

            return;
        }

        this.erc20Game = new IERC20Game(this.tokenObj, this.address, this.playeraddress, this.ercHandler.bind(this))
        this.erc20Game.init()



        this.contract.methods
            .get_config(this.tokenObj.address)
            .call({ from: this.account })
            .then(function (result) {
                if (result.enabled) {
                    this.messageHandler({
                        type: 'limits',
                        min: Web3.utils.fromWei(result.min_bet, this.tokenObj.decimals),
                        max: Web3.utils.fromWei(result.max_bet, this.tokenObj.decimals)
                    })
                }
            }.bind(this), function (params) {
                this.messageHandler({ type: 'error', msg: 'User rejected code:' + params.code })
                this.messageHandler({ type: 'betbutton' })
            }.bind(this))


    }

    unlock() {
        this.erc20Game.unlock()
    }

    ercHandler(msg) {
        switch (msg.type) {
            case 'allowance':
                if (msg.value == '0') {
                    this.messageHandler({ type: 'tokennotalowed' })
                } else {
                    this.messageHandler({ type: 'tokenalowed' })
                }
                break;
            case 'unlock':
                if (msg.value) {
                    this.messageHandler({ type: 'tokenalowed' })
                } else {
                    this.messageHandler({ type: 'tokennotalowed' })
                }
                break;
            case 'balance':
                var bal = Web3.utils.fromWei(msg.value, this.tokenObj.decimals)
                this.messageHandler({ type: 'balance', balance: bal, currency: this.tokenObj.name.toUpperCase() })
                break;
            default:
                break;
        }

    }



}

