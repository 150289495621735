
import HistoryWatcher from "./HistoryWatcher"
//import HttpsHistoryWatcher from "./HttpsHistoryWatcher"

import Web3 from "web3";
import chains from "../config/index.js";
import { store } from '@/store';
import GameHelper from "../games/helpers/gameHelper.js";

export default class HistoryManager {
    betHistory = []
    betHistoryAll = {
        my: [],
        all: []
    }
    currentBetHistoryList = 'ALL'
    historyWatchers = []
    contractData = null
    gameHelper = null
    constructor(_historyHandler, _game, _contractData) {
        this.game = _game
        this.historyHandler = _historyHandler
        this.contractData = _contractData
        this.gameHelper = new GameHelper(_game)
        try {
            if (localStorage) {
                if (localStorage.getItem(`betHistory${this.game}All`) != null) {
                    this.betHistoryAll = JSON.parse(localStorage.getItem(`betHistory${this.game}All`));
                }
                if (localStorage.getItem('betAmount') != null) {
                    this.amount = parseFloat(localStorage.getItem('betAmount'));
                }
                if (localStorage.getItem(`currentBetHistory${this.game}List`) != null) {
                    this.currentBetHistoryList = localStorage.getItem(`currentBetHistory${this.game}List`);
                }
            }
        } catch (error) {
            this.betHistoryAll = {
                my: [],
                all: []
            }
            delete localStorage.betHistoryAll
        }

        if (this.currentBetHistoryList == 'MY') {
            this.betHistory = this.betHistoryAll.my
        } else {
            this.betHistory = this.betHistoryAll.all
        }

        var walletFound = true
        if (window.ethereum == null && window.web3 == null) {
            walletFound = false
        }

        console.log(walletFound)
        var chainList = []
        if (walletFound) {
            chainList = store.getters.getConfig.games[this.game].history
        } else {
            for (const chain in chains) {
                if (Object.hasOwnProperty.call(chains, chain)) {
                    const element = chains[chain];
                    if (element.games[this.game]) {
                        if (element.games[this.game].active) {
                            chainList.push(element.name)
                        }
                    }

                }
            }
        }
        for (let index = 0; index < chainList.length; index++) {
            const element = chainList[index];

            var hw = null

            if (chains[element].wss.indexOf('https://') == 0) {
                hw = new HistoryWatcher(
                    this.getLogsHandler.bind(this),
                    this.contractData,
                    chains[element]
                )
            } else {
                hw = new HistoryWatcher(
                    this.getLogsHandler.bind(this),
                    this.contractData,
                    chains[element]
                )
            }

            hw.init()
            this.historyWatchers.push(hw)
        }


    }

    destroy() {
        for (let index = 0; index < this.historyWatchers.length; index++) {
            this.historyWatchers[index].destroy();
        }
    }

    addAllBetHistory(betObj) {
        this.betHistoryAll.my.unshift({
            address: "MY",
            bet: betObj.bet,
            tx_id: betObj.tx_id,
            win: betObj.win,
            currency: betObj.currency,
        });
        while (this.betHistoryAll.my.length > 10) {
            this.betHistoryAll.my.pop();
        }

        this.betHistoryAll.all.unshift(betObj);
        while (this.betHistoryAll.all.length > 10) {
            this.betHistoryAll.all.pop();
        }
        if (localStorage) {
            localStorage.setItem(`betHistory${this.game}All`, JSON.stringify(this.betHistoryAll));
        }
    }

    addBetHistory(betObj, list) {
        if (list == 'MY') {
            this.betHistoryAll.my.unshift(betObj);
            while (this.betHistoryAll.my.length > 10) {
                this.betHistoryAll.my.pop();
            }
        } else {
            this.betHistoryAll.all.unshift(betObj);
            while (this.betHistoryAll.all.length > 10) {
                this.betHistoryAll.all.pop();
            }
        }
        if (localStorage) {
            localStorage.setItem(`betHistory${this.game}All`, JSON.stringify(this.betHistoryAll));
        }
    }

    setBetHistoryList(list) {
        this.currentBetHistoryList = list
        if (list == 'MY') {
            this.betHistory = this.betHistoryAll.my
        } else {
            this.betHistory = this.betHistoryAll.all
        }
        if (localStorage) {
            localStorage.setItem('currentBetHistoryList', this.currentBetHistoryList);
        }
    }



    getLogsHandler(event, token) {
        var eventType = "Bet"
        if (this.game == "videopoker") {
            eventType = "Win"
        }
        if (event.event == eventType) {
            //var token = this.gameHelper.getTokensByAdress(event.returnValues.token)
            console.log(event.returnValues.up)
            if (token) {
                var betObj = {
                    address: event.returnValues.player,
                    tx_id: event.transactionHash,
                    bet: Web3.utils.fromWei(event.returnValues.bet, token.decimals),
                    win: Web3.utils.fromWei(event.returnValues.win, token.decimals),
                    currency: token.name,
                }
                if (event.returnValues.up !== undefined) {
                    betObj.up = event.returnValues.up
                }

                if (event.returnValues.position !== undefined) {
                    betObj.position = event.returnValues.position
                }
                if (event.returnValues.number !== undefined) {
                    betObj.number = (parseFloat(event.returnValues.number) / 100).toFixed(2)
                }
                if (event.returnValues.output !== undefined) {
                    betObj.number = event.returnValues.output
                }
                if (event.returnValues.input !== undefined) {
                    betObj.number = event.returnValues.input
                }
                if (event.returnValues.banker_amount !== undefined) {
                    betObj.banker_amount = event.returnValues.banker_amount
                }
                if (event.returnValues.banker_cards !== undefined) {
                    betObj.banker_cards = event.returnValues.banker_cards
                }
                if (event.returnValues.banker_points !== undefined) {
                    betObj.banker_points = event.returnValues.banker_points
                }
                if (event.returnValues.player_amount !== undefined) {
                    betObj.player_amount = event.returnValues.player_amount
                }
                if (event.returnValues.player_cards !== undefined) {
                    betObj.player_cards = event.returnValues.player_cards
                }
                if (event.returnValues.player_points !== undefined) {
                    betObj.player_points = event.returnValues.player_points
                }
                if (event.returnValues.tie_amount !== undefined) {
                    betObj.tie_amount = event.returnValues.tie_amount
                }
                this.historyHandler(betObj, 'ALL')
            }
        }
    }
}

