import { store } from '@/store';
import chains from '../../config/index.js'

export default class GameHelper {
    constructor(_game) {
        this.game = _game;
    }

    getRedirectUrl(game, networkVersion) {
        var url = null
        window.Logger.log(game + " " + networkVersion)
        for (const chain in chains) {
            if (Object.hasOwnProperty.call(chains, chain)) {
                const element = chains[chain];
                if (element.network == networkVersion) {
                    window.Logger.log(element.name)
                    url = "/"+game + "/" + element.name
                }

            }
        }
        return url
    }

    getChainsList(game) {
        var list = []

        for (const chain in chains) {
            if (Object.hasOwnProperty.call(chains, chain)) {
                const element = chains[chain];
                if(element.games[game].active){
                    var chainObj = {
                        blockchain_name:element.blockchain_name,
                        currency_codes:[]
                    }
                    if(element.games[game].tokens){
                        for (const token in element.tokens) {
                            if (Object.hasOwnProperty.call(element.tokens, token)) {
                                chainObj.currency_codes.push(token.toUpperCase())
                            }
                        }
                    }else{
                        chainObj.currency_codes.push(element.currency_code)
                    }
                    list.push(chainObj)
                }
            }
        }
        return list
    }




    getTokensByAdress(address) {
        var tokens = store.getters.getTokensList
        for (const key in tokens) {
            if (Object.hasOwnProperty.call(tokens, key)) {
                const element = tokens[key];
                if (element.address == address) {
                    return element;
                }
            }
        }

        if (store.getters.getConfig.default_token.address == address) {
            return store.getters.getConfig.default_token
        }

        return null
    }


    adjustBet(amount) {
        if (amount < store.getters.getGameLimits[this.game].min) {
            amount = parseFloat(store.getters.getGameLimits[this.game].min);
        }
        if (amount > store.getters.getGameLimits[this.game].max) {
            amount = parseFloat(store.getters.getGameLimits[this.game].max);
        }

        return amount.toFixed(5) * 1
    }
    modifyBet(amount, type) {
        if (type == 1) {
            return this.adjustBet(parseFloat(amount) * 2);
        }

        if (type == 2) {
            return this.adjustBet(parseFloat(amount) / 2);
        }
    }
    verify(check, params) {
        var res = true
        switch (check) {
            case 'betAmount':
                var amount = parseFloat(params)
                res = amount < store.getters.getGameLimits[this.game].min ||
                    amount > store.getters.getGameLimits[this.game].max || amount == 'NaN' ? false : true;
                break;
        }
        return res;
    }
    getMessage(k, params) {
        var msgs = {
            'invalid_bet_amount': 'Bet must be in range of ' + store.getters.getGameLimits[this.game].min + ' and ' + store.getters.getGameLimits[this.game].max + ' ' + store.getters.getCurrency,
            'you_won_amount': 'You won ' + params + ' ' + store.getters.getCurrency,
            'select_keno_items': 'You must select at least one ball'
        }
        return msgs[k]
    }
    autobetStart(settings) {
        settings.state = true;
        return settings;
    }
    autobetStop(settings) {
        if (typeof settings == 'undefined' || !settings) {
            return false
        }
        settings.state = false
        settings.profit = 0
        settings.bet.current = settings.bet.initial ?? this.store.getters.getGameLimits[this.game].min
        settings.bet.current = this.adjustBet(settings.bet.current)
        return settings
    }
    autobet(settings, result) {
        if (!settings.state) {
            return settings; //autobet not configured
        }
        //convert to numbers
        var betAmount = Number(result.bet);
        var winAmount = Number(result.win);
        //set initial bet amount
        if (settings.bet.initial == 0) {
            settings.bet.initial = betAmount;
        }
        //update autobet results
        settings.randomNumbers.make = false;
        settings.bet.current = betAmount;
        settings.profit -= betAmount;
        settings.profit += winAmount;
        //stop autobet if onProfitWin conditions met 
        if (
            settings.onProfitWin > 0 &&
            settings.profit >= settings.onProfitWin
        ) {
            settings.state = false;
            settings.profit = 0;
            settings.bet.current = this.adjustBet(settings.bet.current)
            return settings;
        }
        //stop autobet if onProfitLoss conditions met
        if (
            settings.onProfitLoss > 0 &&
            settings.profit <= settings.onProfitLoss * -1
        ) {
            settings.state = false;
            settings.profit = 0;
            settings.bet.current = this.adjustBet(settings.bet.current)
            return settings;
        }
        //adjust bet amount if won
        if (winAmount >= betAmount) {
            if (settings.onWin > 0) {
                settings.bet.current += (settings.bet.current * (Number(settings.onWin) / 100));
            } else if (!settings.onWinState) {
                settings.bet.current = settings.bet.initial;
            }
            settings.randomNumbers.make = settings.randomNumbers.onWin
        }
        //adjust bet amount if lost
        if (winAmount < betAmount) {
            if (settings.onLoss > 0) {
                settings.bet.current += (settings.bet.current * (Number(settings.onLoss) / 100));
            } else if (!settings.onLossState) {
                settings.bet.current = settings.bet.initial;
            }
            settings.randomNumbers.make = settings.randomNumbers.onLoss
        }
        //adjust new bet to limits
        settings.bet.current = this.adjustBet(settings.bet.current)

        return settings;
    }

    sleep(milliseconds) {
        const date = Date.now();
        let currentDate = null;
        do {
            currentDate = Date.now();
        } while (currentDate - date < milliseconds);
    }
}