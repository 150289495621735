<template>
  <transition name="popup-fade">
    <div class="popup-backdrop">
      <div
        class="popup"
        role="dialog"
        aria-labelledby="popupTitle"
        aria-describedby="popupDescription"
      >
        <header class="popup-header" id="popupTitle">
          {{ title }}
          <button
            v-if="!readonly"
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close popup"
          >
            x
          </button>
        </header>

        <section
          class="popup-body"
          :class="fontSize ? 'normal' : ''"
          id="popupDescription"
          v-html="body"
        ></section>

        <footer class="popup-footer" v-if="footer">
          {{footer}}
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "popup",
  props: ["title", "body", "footer", "fontSize", "readonly"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style>
.popup-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  padding-top: 10vh;
  z-index: 999 !important;
}

.popup {
  position: absolute;
  width: 600px;
  max-width: 95vw;
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: block;
  margin: 0 auto;
  border-radius: 5px;
  z-index: 999 !important;
}

.popup-header,
.popup-footer {
  padding: 10px;
  display: flex;
  z-index: 999 !important;
}

.popup-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #262626;
  justify-content: space-between;
  z-index: 999 !important;
}

.popup-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  z-index: 999 !important;
}

.popup-body {
  position: relative;
  padding: 20px 10px;
  text-align:left;
  padding-top: 0px;
  max-height: 70vh;
  overflow-y: auto;
  font-size: 11px; 
  z-index: 999 !important;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #262626;
  background: transparent;
  margin-top: -0.25rem
}

.btn-green {
  color: white;
  background: #262626;
  border: 1px solid #262626;
  border-radius: 2px;
}

.popup-fade-enter,
.popup-fade-leave-to {
  opacity: 0;
}

.popup-fade-enter-active,
.popup-fade-leave-active {
  transition: opacity 0.5s ease;
}

.popup-body.normal  {
  font-size: 14px;
}
</style>